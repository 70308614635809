import RBush from "rbush";
import "../util/leaflet.control.select/leaflet.control.select.js";
import "../util/leaflet.control.select/leaflet.control.select.css";

import "@bopen/leaflet-area-selection/dist/index.css";
import PQueue from "p-queue";

import { elen, Ontology } from "../primitives";

const TILE_SIZE = 512;

//FIXME: not drawing rings
const draw_one_poly = (ctx, coord) => {
  ctx.moveTo(coord[0][0][0], coord[0][0][1]);

  for (let i = 1; i < coord[0].length; i++) {
    ctx.lineTo(coord[0][i][0], coord[0][i][1]);
  }
};

function draw(ctx, geom, color) {
  let coordinates = geom.coordinates;
  if (geom.type === "Polygon") {
    ctx.beginPath();
    draw_one_poly(ctx, coordinates);
    ctx.closePath();
  } else if (geom.type === "MultiPolygon") {
    ctx.beginPath();
    for (const coord of coordinates) {
      draw_one_poly(ctx, coord);
    }
    ctx.closePath();
  }
  ctx.strokeStyle = color; // Set the stroke color
  ctx.stroke();
}

function getNthElements(array, startIndex, interval) {
  if (startIndex < 0 || startIndex >= interval) {
    throw new Error("Invalid start index or interval.");
  }

  const result = [];
  for (let i = startIndex; i < array.length; i += interval) {
    result.push(array[i]);
  }

  return result;
}

const ElenLayer = L.GridLayer.extend({
  options: {
    minNativeZoom: 0,
    tileSize: 512,
    minZoom: -2,
    maxNativeZoom: 0,
    updateWhenIdle: false, // prevent unnecessary renders
    updateWhenZooming: false, // prevent unnecessary renders
    debug: 0,
    opacity: 0.7,
    zIndex: 10,
    bounds: [
      [100000, 100000],
      [0, 0],
    ],
    geometry_var: "geometry",
  },

  initialize: function (
    elen_obj,
    map,
    ontology = new Ontology({}),
    options = {}
  ) {
    try {
      this.elen = elen_obj;
      this.ontology = ontology;

      // Update options
      Object.entries(options).forEach(([k, v]) => {
        this.options[k] = v;
      });
      L.setOptions(this, this.options);

      if (this.options.debug >= 1)
        console.log("Elen layer initializing...", { elen_obj, map, options });

      this._ready = false;
      this._tree = new RBush();

      this._setupCallback = options.setupCallback || this.options.setupCallback;
      this._map =
        this._map === undefined || this._map === null ? map : this._map;

      this._minLoadZoom = -1;
      this._prevZoom = undefined;

      this._pqueue = new PQueue({ concurrency: 5 });

      this._pcount = 0;
      this._pqueue.on("active", () => {
        if (this.options.debug > 0) {
          console.log(
            `Working on item #${++this._pcount}.  Size: ${
              this._pqueue.size
            }  Pending: ${this._pqueue.pending}. Queue: `,
            { queue: this._pqueue }
          );
        }
      });
      this._pqueue.on("add", () => {
        if (this.options.debug > 0) {
          console.log(
            `Task is added.  Size: ${this._pqueue.size}  Pending: ${this._pqueue.pending}`
          );
        }
      });

      this._pqueue.on("next", () => {
        if (this.options.debug > 0) {
          console.log(
            `Task is completed.  Size: ${this._pqueue.size}  Pending: ${this._pqueue.pending}`
          );
        }
      });

      L.GridLayer.prototype.initialize.apply(this, arguments);

      this.updateOntology = ([key, _], e) => {
        // Check if checked or unchecked
        const selected = e.target.checked;
        this.ontology.options[key].visible = selected;
        // Redraw tiles with subset of feature types
        this.redraw();
      }; // Called on checkbox click

      this._ready = true;
      if (this.options.debug >= 1)
        console.log(`Setup complete in ${this._setupTime} s.`);
      if (this._setupCallback) {
        this._setupCallback();
      }
    } catch (error) {
      console.warn("[L.elen] Error initializing ElenLayer", error);
      console.trace();
    }
  },

  onAdd: function (map) {
    L.GridLayer.prototype.onAdd.apply(this, map);
    // this._annotation_control.addTo(this._map);
  },

  onRemove: function (map) {
    L.GridLayer.prototype.onRemove.call(this, map);
    // this._annotation_control.remove(map);
  },

  _update(center) {
    const map = this._map;
    if (!map) {
      return;
    }

    const rawZoom = map.getZoom();
    const zoom = this._clampZoom(rawZoom);

    // Necessary to account for smooth zoom plugin
    if (
      !this.updateWhenZooming &&
      this._prevZoom &&
      rawZoom !== this._prevZoom
    ) {
      this._prevZoom = rawZoom;
      return; // Don't update if someone is still zooming in
    }

    this._prevZoom = rawZoom;

    if (center === undefined) {
      center = map.getCenter();
    }

    if (this._tileZoom === undefined) {
      return;
    } // if out of minzoom/maxzoom

    let pixelBounds = this._getTiledPixelBounds(center),
      fullTileRange = this._pxBoundsToTileRange(pixelBounds),
      tileCenter = fullTileRange.getCenter(),
      queue = [],
      margin = this.options.keepBuffer || 10,
      noPruneRange = new L.Bounds(
        fullTileRange.getBottomLeft().subtract([margin, -margin]),
        fullTileRange.getTopRight().add([margin, -margin])
      );

    let tileRange = new L.Bounds(
      fullTileRange.getTopLeft().add([1, 1]),
      fullTileRange.getBottomRight().subtract([1, 1])
    );

    // Sanity check: panic if the tile range contains Infinity somewhere.
    if (
      !(
        isFinite(tileRange.min.x) &&
        isFinite(tileRange.min.y) &&
        isFinite(tileRange.max.x) &&
        isFinite(tileRange.max.y)
      )
    ) {
      throw new Error("Attempted to load an infinite number of tiles");
    }

    for (const key in this._tiles) {
      if (Object.hasOwn(this._tiles, key)) {
        const c = this._tiles[key].coords;
        if (
          c.z !== this._tileZoom ||
          !noPruneRange.contains(new L.Point(c.x, c.y))
        ) {
          this._tiles[key].current = false;
        }
      }
    }

    // _update just loads more tiles. If the tile zoom level differs too much
    // from the map's, let _setView reset levels and prune old tiles.
    if (Math.abs(zoom - this._tileZoom) > 1) {
      this._setView(center, zoom);
      return;
    }

    // create a queue of coordinates to load tiles from
    for (let j = tileRange.min.y; j <= tileRange.max.y; j++) {
      for (let i = tileRange.min.x; i <= tileRange.max.x; i++) {
        const coords = new L.Point(i, j);
        coords.z = this._tileZoom;

        if (!this._isValidTile(coords)) {
          continue;
        }

        // Check if its an outerbound tile --
        let priority = 0;

        const tile = this._tiles[this._tileCoordsToKey(coords)];

        if (tile) {
          // No change in prority, don't re-add to queue
          tile.current = true;
        } else {
          queue.push({
            coords,
            options: {
              priority: priority,
            },
          });
        }
      }
    }

    // sort tile queue to load tiles in order of their distance to center
    queue.sort(
      (a, b) =>
        a.coords.distanceTo(tileCenter) - b.coords.distanceTo(tileCenter)
    );

    if (this.options.debug > 2) {
      console.log("[L.elen | update] Tile queue: ", {
        "queue.length": queue.length,
        queue,
      });
    }

    if (queue.length !== 0) {
      // if it's the first batch of tiles to load
      if (!this._loading) {
        this._loading = true;
        // @event loading: Event
        // Fired when the grid layer starts loading tiles.
        this.fire("loading");
      }

      // create DOM fragment to append tiles in one batch
      const fragment = document.createDocumentFragment();

      const MAX_QUEUE_LENGTH = 5;
      const numTiles = Math.min(MAX_QUEUE_LENGTH, queue.length);
      for (let i = 0; i < numTiles; i++) {
        const front = queue[i];
        this._addTile(front.coords, fragment, front.options);
      }

      this._level.el.appendChild(fragment);
      if (this.options.debug > 2) {
        console.log(`[L.elen | update] ${numTiles} tiles added`);
      }
    }
  },

  /**
   * Draws geometries from features in chunk on an HTML
   * Canvas.
   * @param {Chunk} chunk chunk object containing loaded features
   * @param {Object} coords tile x, y coordinates
   * @param {HTMLCanvasElement} tile Tile that will be drawn on
   * @param {Function} done Leaflet callback to signal that drawing is completed
   */
  drawChunk: function (chunk, coords, tile, done) {
    const { x, y } = coords;
    const mpp = 1; // Note: This is true at zoom level 0
    const tile_size = TILE_SIZE * mpp;

    const x_mu = x * tile_size;
    const y_mu = y * tile_size;

    const tile_xmin = x_mu;
    const tile_xmax = x_mu + tile_size;
    const tile_ymin = y_mu;
    const tile_ymax = y_mu + tile_size;

    // FIXME(vrishk)
    if (
      this._annotation_control &&
      this._annotation_control._get_tile_id({ x, y }) ===
        this._annotation_control._tile_buffer.tile.id
    ) {
      this._annotation_control._tile_buffer.tile.html = tile; // Update tile html for annotation layer to manipulate
      tile.style.boxShadow = "0 0 40px rgba(0, 0, 0, 0.8)"; // Set background on selection
      console.log("[EL] Skipping", coords); // Does not draw nuclei, annotating
      return;
    }

    const ctx = tile.getContext("2d");

    // FIXME: Naive split between File / Dir
    if (this.elen instanceof elen.Elen) {
      try {
        let data = chunk;

        const numObjects = data[this.options.geometry_var].data.length;

        // let counter = 0;
        for (let i = 0; i < numObjects; i++) {
          const objWkb = data[this.options.geometry_var].data.get(i);
          if (objWkb.length === 0) {
            continue;
          }
          let type = "neoplastic";
          let metadata = {};

          if (data["pred"]) {
            type = data["pred"].data.get(i);
          }

          if (data["probs"]) {
            metadata.probs = getNthElements(data["probs"].data, i, numObjects);
          }

          if (data["score"]) {
            metadata.score = data["score"].data[i];
          }

          if (!this.ontology.options[type].visible) continue; // Don't draw if not among visible types

          try {
            const geom = elen.wkb2geom(objWkb);
            if (!geom) {
              continue;
            }
            draw(
              ctx,
              elen.transform(geom, ([x, y]) => [x - x_mu, y - y_mu]),
              this.ontology.options[type]["color"]
            );

            // Each wkx is a polygon
            const bounds = L.bounds(elen.coords(geom));
            const bbox = {
              minX: bounds.min.x,
              minY: bounds.min.y,
              maxX: bounds.max.x,
              maxY: bounds.max.y,
            };

            let id = [x_mu, y_mu, i].join(".");
            const search = this._tree.search(bbox);

            if (
              search.length === 0 ||
              !search.map((f) => f.id === id).every((bool) => bool)
            ) {
              const feat = {
                type: "Feature",
                geometry: elen.transform(geom, ([x, y]) => [y, x]),
                properties: {
                  id: id,
                  type: type,
                  tile: [x, y],
                  ...metadata,
                },
              };
              this._tree.insert({
                feature: feat,
                ...bbox,
              });
            }
          } catch (err) {
            console.error(
              `[L.elen] _chunkToTile | Error adding nuclei at index ${i} with metadata: `,
              {
                objWkb,
              },
              err
            );
          }
        }
      } catch (err) {
        console.error("[ELEN] Error drawing chunk: ", err);
      }
    } else {
      let missing_types = [];
      for (let i of Object.keys(chunk)) {
        let feature = chunk[i];
        const { geometry: geom, properties } = feature;

        let type = properties?.type ?? "neoplastic";

        try {
          if (
            type in this.ontology.options &&
            !this.ontology.options[type].visible
          )
            continue;
          let color;
          if (type in this.ontology.options) {
            color = this.ontology.options[type]["color"];
          } else {
            color = "#000000";
            if (!missing_types.includes(type)) {
              missing_types.push(type);
            }
          }
          draw(
            ctx,
            elen.transform(geom, ([x, y]) => [x - x_mu, y - y_mu]),
            color
          );

          // Each wkx is a polygon
          const bounds = L.bounds(elen.coords(geom));
          const bbox = {
            minX: elen.clamp(bounds.min.x, tile_xmin, tile_xmax),
            minY: elen.clamp(bounds.min.y, tile_ymin, tile_ymax),
            maxX: elen.clamp(bounds.max.x, tile_xmin, tile_xmax),
            maxY: elen.clamp(bounds.max.y, tile_ymin, tile_ymax),
          };

          let id = [x_mu, y_mu, i].join(".");
          const search = this._tree.search(bbox);

          if (
            search.length === 0 ||
            !search.map((f) => f.id === id).every((bool) => bool)
          ) {
            const feat = {
              type: "Feature",
              geometry: geom,
              properties: {
                id: id,
                type: type,
                tile: [x, y],
                ...properties,
                bbox: bbox,
              },
            };
            this._tree.insert({
              feature: feat,
              ...bbox,
            });
          }
        } catch (err) {
          console.error(
            `[L.elen] _chunkToTile | Error adding nuclei at index ${i} with metadata: `,
            {
              feature,
            },
            err
          );
        }
      }
    }

    try {
      done && done(null, tile);
    } catch {}
    return tile;
  },

  createTile: function (coords, done, options) {
    const { x, y, z } = coords;
    const coords_subset = { x, y };
    let coords_mu = { x: coords.x * TILE_SIZE, y: coords.y * TILE_SIZE };

    // Create tile
    let tile = L.DomUtil.create("canvas", "leaflet-tile");
    // we do this because sometimes css normalizers will set * to box-sizing: border-box
    tile.style.boxSizing = "content-box";

    // Set width and height so polygons are drawn correctly relative to tileSize;
    let tileSize = this.getTileSize();
    tile.width = tileSize.x;
    tile.height = tileSize.y;
    tile.style.visibility = "visible";

    tile.style.pointerEvents = "initial";

    tile.addEventListener("mouseover", (event) => {
      if (this._annotation_control) {
        this._annotation_control.tile_mouseover(event, tile, coords_subset);
      }
    });

    tile.addEventListener("mouseout", (event) => {
      if (this._annotation_control) {
        this._annotation_control.tile_mouseout(event, tile, coords_subset);
      }
    });

    let drag = false;
    let mouseDownTime = 0;
    const dragTimeThreshold = 200; // Adjust this threshold as needed (in milliseconds)

    tile.addEventListener("mousedown", () => {
      drag = false;
      mouseDownTime = Date.now();
    });

    tile.addEventListener("mousemove", () => {
      if (Date.now() - mouseDownTime > dragTimeThreshold) {
        drag = true;
      }
    });

    tile.addEventListener("mouseup", (event) => {
      if (!drag) {
        if (this._annotation_control) {
          this._annotation_control.tile_click(event, tile, coords_subset);
        }
      } else {
      }
    });

    if (!(this._ready && this.elen)) {
      return tile;
    }

    // FIXME(ekkin2): Remove this conditional when ZarrElen is standardized
    let tile_data;
    if (this.elen instanceof elen.Elen) {
      tile_data = this.elen.get_chunk([x, y, 0]);
    } else {
      tile_data = this.elen.read([x, y, 0].join(".")); // Promise<Chunk>
    }

    let curres; // NOTE: Used for debugging
    this._pqueue
      // NOTE: Diff - promiseObjectAll(tile_data) vs. tile_data
      .add(() => tile_data, {
        priority: options?.priority ? options.priority : 0,
      })
      .then((res) => {
        // let data = Object.fromEntries(res);
        // FIXME(ekkin2): Unify implementations so that internal functions abstract differences
        curres = res;
        let data =
          this.elen instanceof elen.Elen ? Object.fromEntries(res) : res; // Chunk object
        // let data = res;
        if (res) {
          if (this.options.debug > 2)
            console.log("[L.elen] promise.all res: ", { res });
          return this.drawChunk(data, coords, tile, done);
        }
      })
      .catch((err) => {
        console.error("[L.elen] Error on fulfilling promises in createTile: ", {
          err,
          curres,
        });
      });

    return tile;
  },

  _addTile(coords, container, options) {
    const tilePos = this._getTilePos(coords),
      key = this._tileCoordsToKey(coords);

    const tile = this.createTile(
      this._wrapCoords(coords),
      this._tileReady.bind(this, coords), // done callback
      options
    );

    try {
      this._initTile(tile);
    } catch {}

    // if createTile is defined with a second argument ("done" callback),
    // we know that tile is async and will be ready later; otherwise
    if (this.createTile.length < 2) {
      // mark tile as ready, but delay one frame for opacity animation to happen
      try {
        L.Util.requestAnimFrame(this._tileReady.bind(this, coords, null, tile));
      } catch {}
    }

    L.DomUtil.setPosition(tile, tilePos);

    // save tile in cache
    this._tiles[key] = {
      el: tile,
      coords,
      current: true,
    };

    container.appendChild(tile);
    // @event tileloadstart: TileEvent
    // Fired when a tile is requested and starts loading.
    this.fire("tileloadstart", {
      tile,
      coords,
    });
  },

  _updateOpacity() {
    if (!this._map) {
      return;
    }

    try {
      this._container.style.opacity = this.options.opacity;
    } catch {}

    const now = +new Date();
    let nextFrame = false,
      willPrune = false;

    for (const key in this._tiles) {
      if (!Object.hasOwn(this._tiles, key)) {
        continue;
      }

      const tile = this._tiles[key];
      if (!tile.current || !tile.loaded) {
        continue;
      }

      const fade = Math.min(1, (now - tile.loaded) / 200);

      try {
        tile.el.style.opacity = fade;
      } catch {}
      if (fade < 1) {
        nextFrame = true;
      } else {
        if (tile.active) {
          willPrune = true;
        } else {
          this._onOpaqueTile(tile);
        }
        tile.active = true;
      }
    }

    if (willPrune && !this._noPrune) {
      this._pruneTiles();
    }

    if (nextFrame) {
      L.Util.cancelAnimFrame(this._fadeFrame);
      this._fadeFrame = L.Util.requestAnimFrame(this._updateOpacity, this);
    }
  },

  statics: {
    from_url: async function (url, head_url, ontology, map, options = {}) {
      let elenfile = await new elen.Elen(url, head_url);
      return new ElenLayer(elenfile, map, ontology, options);
    },
    dir_from_url: async function (url, url_sign, ontology, map, options = {}) {
      let elendir = new elen.ElenDir(url, "geojson.gz", url_sign);
      return new ElenLayer(elendir, map, ontology, options);
    },
  },
});

export { ElenLayer };
