import { useState } from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Radio,
  Checkbox,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Draggable from "react-draggable";

const OntologyLayer = ({ layer, layerObj, editLayerObj, map }) => {
  const { ontology, edit: editable, uri } = layer;
  // console.log({ layerObj });
  let defaultCheckbox = {};
  Object.keys(ontology.options).forEach((k) => {
    defaultCheckbox[k] = ontology.options[k].visible;
  });

  const [visible, setVisible] = useState(layer?.visible ?? false);
  const [radioSelectedValue, setRadioSelectedValue] = useState(null);
  const [checkboxState, setCheckboxState] = useState(defaultCheckbox);

  let updateOntology = layerObj?.updateOntology;
  if (editable && editLayerObj?.callbacks) {
    editLayerObj.callbacks.update_ontology_control = setRadioSelectedValue;
    updateOntology = editLayerObj?.checkbox_callback;
  }

  const handleRadioChange = (event) => {
    setRadioSelectedValue(event.target.value);
    if (editLayerObj) {
      editLayerObj.radio_callback(event.target.value);
    } else {
      console.warn(`[ONTOLOGY] No \`editLayerObj\` found for editable layer.`);
    }
  };

  const handleCheckboxChange = (k, event) => {
    setCheckboxState({
      ...checkboxState,
      [k]: event.target.checked,
    });

    if (updateOntology) {
      updateOntology([k, undefined], event);
    }
  };

  const updateVisibility = () => {
    let newVisibility = !visible;

    if (newVisibility) {
      layerObj.addTo(map);
    } else {
      layerObj.remove();
    }

    setVisible(!visible);
  };

  return (
    <Accordion sx={{ m: 1 }} key={layer.id}>
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            sx={{
              fontSize: 24,
              pointerEvents: "auto",
              "&:hover": {
                bgcolor: "rgb(240, 240, 240)",
                borderRadius: 20,
              },
            }}
          />
        }
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{ pointerEvents: "none" }}
      >
        <IconButton
          size={"small"}
          sx={{
            pointerEvents: "auto",
            height: "40px",
            width: "40px",
            marginLeft: -1,
          }}
          onClick={(event) => {
            event.stopPropagation();
            updateVisibility();
          }}
        >
          {visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </IconButton>
        <Typography sx={{ fontSize: 11, overflowWrap: "anywhere" }}>
          {uri}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 2 }}>
        <Box>
          {Object.keys(ontology.options).map((k) => (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                mb: -1,
              }}
              key={k}
            >
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                {editable && (
                  <Radio
                    checked={radioSelectedValue === k}
                    onChange={handleRadioChange}
                    value={k}
                    size="small"
                    sx={{
                      p: 0,
                      mr: 0.5,
                      color: ontology.options[k].color,
                      "&.Mui-checked": {
                        color: ontology.options[k].color,
                      },
                    }}
                  />
                )}

                <Typography sx={{ fontSize: 12, overflowWrap: "anywhere" }}>
                  {k}
                </Typography>
              </Box>
              <Box sx={{ float: "right" }}>
                <Checkbox
                  checked={checkboxState[k]}
                  onChange={(event) => {
                    handleCheckboxChange(k, event);
                  }}
                  size={"small"}
                  sx={{
                    "&.Mui-checked": {
                      color: ontology.options[k].color,
                    },
                  }}
                />
              </Box>
            </Box>
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

const OntologyControl = ({ layers, layerObjs, editLayerObj, map }) => {
  const eventControl = (event, info) => {
    event.stopPropagation();
    event.preventDefault();
  };
  return (
    <Draggable onDrag={eventControl} onStop={eventControl}>
      <Box
        sx={{
          position: "absolute",
          bottom: 20,
          right: 80,
          width: 250,
          height: "40vh",
          zIndex: 1000,
          backgroundColor: "rgba(255, 255, 255, 0.85)",
          borderRadius: 2,
          boxShadow: 4,
          overflow: "scroll",
        }}
      >
        {layers.map((layer) => {
          const { ontology, uri } = layer;
          if (ontology) {
            return (
              <OntologyLayer
                layer={layer}
                layerObj={layerObjs[uri]}
                editLayerObj={editLayerObj}
                map={map}
              />
            );
          } else {
            return <></>;
          }
        })}
      </Box>
    </Draggable>
  );
};

export default OntologyControl;
