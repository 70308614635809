import { get_array, registry } from "../util/zarrita.js/v2.bundle.js";
import { ZipFileStore } from "../util/zarrita.js/zip.bundle.js";
import { Store } from "./store.js";
import { Buffer } from "buffer";
import { Geometry } from "../util/wkx/lib/wkx.js";
import Blosc from "numcodecs/blosc";
import { LRUCache } from "lru-cache";
import turf from "Util/paintpolygon/myTurf.js";
import JSON5 from 'json5';

registry.set(Blosc.codecId, () => Blosc);

const IGNORE_SET = [".zattrs", ".zgroup", ".zmetadata"];
const ELEN_FILETYPES = Object.freeze({
  geojson: "geojson",
  geojson_gz: "geojson.gz",
  zarr: "zarr",
}); // Types enumeration for Elen chunk
const FS_ACTIONS = Object.freeze({
  HEAD: "headObject",
  READ: "getObject",
  WRITE: "putObject",
});

const delayName = ([name, promise]) => {
  return promise.then((result) => [name, result]);
};

/**
 * TODO: Add this to ChunkZarr for backwards compatability
 */
const promiseObjectAll = (object) => {
  const promiseList = Object.entries(object).map(delayName);

  return Promise.all(promiseList);
};

const stream_to_string = async (stream) => {
  let data = "";
  for await (const chunk of stream) {
    data += chunk.toString();
  }
  return data;
};

function array_to_obj(array) {
  const result = array.reduce((acc, obj, index) => {
    if (!("id" in obj.properties)) {
      obj.properties.id = index;
    }
    acc[obj.properties.id] = obj;
    return acc;
  }, {});
  return result;
}

export class ZarrStore extends Store {
  constructor(
    root_url,
    url_sign = url_sign,
    type = FILETYPES.zarr,
    cache_options = { max: 200 }
  ) {
    super(root_url, url_sign, type, cache_options);
    this._ready = false;
    this.initialize();
  }

  async initialize() {
    const head_uri = await this.get_uri("", FS_ACTIONS.HEAD);
    const uri = await this.get_uri("", FS_ACTIONS.READ);

    this.zip_store = await ZipFileStore.fromUrl(uri, head_uri);

    let all_keys = Object.keys((await this.zip_store.info).entries).map(
      (k) => k.split("/")[0]
    );
    this.vars = [];
    new Set(all_keys).forEach((k) => {
      if (!IGNORE_SET.includes(k)) this.vars.push(k);
    });

    this.arrays = {};
    for (const k of this.vars) {
      try {
        let arr = await get_array(this.zip_store, `/${k}`);
        this.arrays[k] = arr;
      } catch (err) {
        console.log("[In Elen]", k, err);
      }
    }
    this._ready = true;
  }

  async read(key = "") {
    if (!this._ready) {
      console.warn("[READ] Zarr still initializing...");
      return;
    }

    let idx = key.split(".");
    if (this._cache.has(key)) {
      return promiseObjectAll(this._cache.get(key));
    }

    let results = Object.fromEntries(
      Object.entries(this.arrays).map(([k, arr]) => {
        if (!arr || idx.length > arr.shape.length) {
          return [k, new Promise((resolve) => resolve(undefined))];
        }

        try {
          let buffered_idx = Array(arr.shape.length - idx.length)
            .fill(0)
            .concat(idx);
          return [k, arr.get_chunk(buffered_idx)];
        } catch (err) {
          return [k, new Promise((resolve) => resolve(undefined))];
        }
      })
    );

    // console.log("[elen_io.js|get_chunk] results: ", results);
    this._cache.set(key, results);
    return promiseObjectAll(results);
  }
}

export class Elen {
  /**
   * Constructor for Elen class. Stores class variables:
   * this. Assumes Zarr as a single file.
   */
  constructor(uri, head_uri, cache_options = { max: 200 }) {
    return (async () => {
      this.zip_store = await ZipFileStore.fromUrl(uri, head_uri);

      let all_keys = Object.keys((await this.zip_store.info).entries).map(
        (k) => k.split("/")[0]
      );
      this.vars = [];
      new Set(all_keys).forEach((k) => {
        if (!IGNORE_SET.includes(k)) this.vars.push(k);
      });

      this.arrays = {};
      for (const k of this.vars) {
        try {
          let arr = await get_array(this.zip_store, `/${k}`);
          this.arrays[k] = arr;
        } catch (err) {
          console.log("[In Elen]", k, err);
        }
      }

      // console.log("[In Elen]", this);

      this._cache = new LRUCache(cache_options);

      return this;
    })();
  }

  get_chunk(idx) {
    if (this._cache.has(idx.join("."))) {
      return promiseObjectAll(this._cache.get(idx.join(".")));
    }

    let results = Object.fromEntries(
      Object.entries(this.arrays).map(([k, arr]) => {
        if (!arr || idx.length > arr.shape.length) {
          return [k, new Promise((resolve) => resolve(undefined))];
        }

        try {
          let buffered_idx = Array(arr.shape.length - idx.length)
            .fill(0)
            .concat(idx);
          return [k, arr.get_chunk(buffered_idx)];
        } catch (err) {
          return [k, new Promise((resolve) => resolve(undefined))];
        }
      })
    );

    // console.log("[elen_io.js|get_chunk] results: ", results);
    this._cache.set(idx.join("."), results);
    return promiseObjectAll(results);
  }
}

/**
 * Class for reading Elen that are formatted as
 * directories and chunk files.
 */
export class ElenDir {
  constructor(
    root_url,
    type = ELEN_FILETYPES.geojson_gz,
    url_sign = url_sign,
    cache_options = { max: 200 }
  ) {
    this.root = root_url;
    this.type = type;
    this.url_sign = url_sign;

    this.url_map = Object.fromEntries(
      Object.values(FS_ACTIONS).map((k) => [k, {}])
    );

    this._cache = new LRUCache({
      ...cache_options,
      dispose: async (cached, _) => {
        if (cached.dirty) {
          await this.flush_chunk(cached.coords, cached.data);
        }
      },
    });
  }

  async get_uri(coords, action = FS_ACTIONS.READ, contentType = undefined) {
    const key = coords.join(".");

    let raw_chunk_uri = [this.root, key].join("");
    switch (this.type) {
      case ELEN_FILETYPES.geojson:
      case ELEN_FILETYPES.geojson_gz: {
        raw_chunk_uri = [raw_chunk_uri, ".", this.type].join("");
        break;
      }
      default: {
        break;
      }
    }

    let chunk_url = undefined;

    if (raw_chunk_uri in this.url_map[action]) {
      chunk_url = this.url_map[action][raw_chunk_uri];
    } else if (this.url_sign) {
      try {
        chunk_url = await this.url_sign(raw_chunk_uri, action, contentType);
        this.url_map[action][raw_chunk_uri] = chunk_url;
      } catch (err) {
        console.error(
          "[elen_io:ElenDir.get_chunk] Error using `this.url_map()` to map chunk_url. ",
          err
        );
        console.trace();
      }
    } else {
      chunk_url = raw_chunk_uri;
      this.url_map[action][raw_chunk_uri] = chunk_url;
    }

    return chunk_url;
  }

  async get_chunk(coords) {
    const key = coords.join(".");

    if (this._cache.has(key)) {
      return this._cache.get(key).data;
    }

    // Get the tile from url endpoint
    let chunk_url = await this.get_uri(coords, FS_ACTIONS.READ);

    let chunk_data, response;
    try {
      response = await fetch(chunk_url);
      if (!response.ok) {
        throw new Error("Chunk was not found or issue parsing to JSON.");
      }
    } catch (err) {
      console.warn(
        `[elen_io:ElenDir.get_chunk] Error fetching chunk =${coords}, ${key}`,
        err
      );
      return {};
    }

    switch (this.type) {
      case ELEN_FILETYPES.geojson: {
        const raw_data = await response.json(); // JSON Response
        chunk_data = array_to_obj(raw_data.features);
        break;
      }
      case ELEN_FILETYPES.geojson_gz: {
        const decoded_stream = (await response.blob())
          .stream()
          .pipeThrough(new DecompressionStream("gzip"));
        let raw_data;
        try {
          raw_data = JSON5.parse(await new Response(decoded_stream).text());
        } catch (err) {
          console.log("Failed parse with err", err);
          console.trace();
        }
        chunk_data = array_to_obj(raw_data.features);
        break;
      }
      default:
        break;
    }
    this._cache.set(key, {
      key: key,
      data: chunk_data,
      dirty: false,
      coords: coords,
    });

    return chunk_data;
  }

  async set_chunk(coords, chunk_data, flush = true) {
    console.log("setting", coords);
    const key = coords.join(".");

    let cached = {};
    if (this._cache.has(key)) {
      cached = this._cache.get(key);
      cached.dirty = true;
      cached.data = chunk_data;
    } else {
      cached = { data: chunk_data, key: key, coords: coords, dirty: true };
    }

    this._cache.set(key, cached);

    if (flush) {
      await this.flush_chunk(coords, chunk_data);
    }
  }

  async flush_chunk(coords, chunk_data) {
    console.log("FLUSHING", coords);
    let request;

    switch (this.type) {
      case ELEN_FILETYPES.geojson: {
        request = {
          body: JSON5.stringify({
            features: Object.values(chunk_data),
            type: "FeatureCollection",
          }),
          headers: {
            "Content-Type": "application/json",
          },
        };
        break;
      }
      case ELEN_FILETYPES.geojson_gz: {
        const data = JSON5.stringify({
          features: Object.values(chunk_data),
          type: "FeatureCollection",
        });
        const stream = new ReadableStream({
          start(controller) {
            controller.enqueue(new TextEncoder().encode(data));
            controller.close();
          },
        });
        const encoded_data = await (
          await new Response(
            stream.pipeThrough(new CompressionStream("gzip"))
          ).blob()
        ).arrayBuffer();

        request = {
          body: encoded_data,
          headers: {
            "Content-Type": "application/octet-stream",
          },
        };
        break;
      }
      default:
        break;
    }
    let chunk_url = await this.get_uri(
      coords,
      FS_ACTIONS.WRITE,
      request.headers["Content-Type"]
    );

    try {
      const response = await fetch(chunk_url, {
        method: "PUT",
        body: request.body,
        headers: {
          ...request.headers,
        },
      });
      if (!response.ok) {
        throw new Error("Issues in flushing chunk");
      }
    } catch (err) {
      console.warn(
        `[elen_io:ElenDir.get_chunk] Error flushing chunk with coords=${coords}`,
        err
      );
    }
    console.log("[FLUSH]", coords);
  }
}

export const transform = (geom, fn) => {
  let ret_geom = Object.assign({}, geom);
  if (ret_geom.type === "Polygon") {
    ret_geom.coordinates = ret_geom.coordinates.map((arr) => arr.map(fn));
  } else if (ret_geom.type === "MultiPolygon") {
    ret_geom.coordinates = ret_geom.coordinates.map((brr) =>
      brr.map((arr) => arr.map(fn))
    );
  }
  return ret_geom;
};

// FIXME: Delete
export const flip_coords = (features) => {
  const out = {};
  for (let id in features) {
    let feature = { ...features[id] }; // Copy
    feature.geometry = transform(feature.geometry, ([x, y]) => [y, x]);
    out[id] = feature;
  }
  return out;
};

export const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

export const squeeze = (arr) => {
  if (!Array.isArray(arr)) return arr;
  else if (arr.length === 1) return flatten(arr[0]);
  else return arr.map(flatten);
};

export const coords = (geom) => {
  geom = clean({ geometry: geom }).geometry;
  if (geom.type === "Polygon") {
    return geom.coordinates.flat(1);
  } else if (geom.type === "MultiPolygon") {
    return geom.coordinates.flat(2);
  }
};

export const wkb2geom = (wkb) => {
  var wkbHex = Array.from(new Uint8Array(wkb))
    .map((byte) => byte.toString(16).padStart(2, "0"))
    .join("");
  var wkbBuffer = new Buffer(wkbHex, "hex");
  let geom = Geometry.parse(wkbBuffer, "hex").toGeoJSON();

  return geom;
};

export const feature_collection = (features) => {
  const fc = {
    type: "FeatureCollection",
    features: features,
  };
  return fc;
};

export const clean = (feature) => {
  if (!feature.geometry?.type || feature.geometry === null) {
    console.warn(
      "[elen_io.js:clean] Feature to clean has no geometry or geometry type. Returned original feature.",
      feature
    );
    return feature;
  }

  switch (feature.geometry.type) {
    case "GeometryCollection": {
      let new_feature = Object.assign({}, feature);
      let fc = feature_collection(
        new_feature.geometry.geometries
          .filter((f) => {
            return f.type === "Polygon" || f.type === "MultiPolygon";
          })
          .map((f) => {
            return { type: "Feature", geometry: f, properties: {} };
          })
      );

      new_feature.geometry = turf.union(fc).geometry;
      return new_feature;
    }
    case "Point": {
      return empty(feature.properties);
    }
    case "Polygon": {
      if (feature.geometry.coordinates.flat().length < 4) {
        return empty(feature.properties);
      } else {
        return feature;
      }
    }
    default:
      return feature;
  }
};

export const circle = (center, radius, steps = 128, properties = {}) => {
  const coordinates = [];

  for (let i = 0; i < steps; i++) {
    const angle = (i / steps) * 2 * Math.PI;
    const latitude = center[0] + radius * Math.cos(angle);
    const longitude = center[1] + radius * Math.sin(angle);

    coordinates.push([longitude, latitude]);
  }

  coordinates.push(coordinates[0]);

  const circle = {
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: [coordinates],
    },
    properties: properties,
  };
  return circle;
};

export const box = (x, y, w, h, properties = {}) => {
  const coordinates = [
    [x, y],
    [x, y + h],
    [x + w, y + h],
    [x + w, y],
  ];

  coordinates.push(coordinates[0]);

  const box = {
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: [coordinates],
    },
    properties: properties,
  };
  return box;
};

export const empty = (properties = {}) => {
  const box = {
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: [],
    },
    properties: properties,
  };
  return box;
};

const arrays_equal = (a1, a2) => {
  if (typeof a1 === "number" && typeof a2 === "number") {
    return a1 === a2;
  } else if (Array.isArray(a1) && Array.isArray(a2)) {
    let all_equal = true;
    if (a1.length === a2.length && a1.length > 0 && a2.length > 0) {
      let n = a1.length; // a1 and a2 lengths are equal
      for (let i = 0; i < n; ++i) {
        let p1 = a1[i];
        let p2 = a2[i];
        all_equal = all_equal && arrays_equal(p1, p2);
      }
    } else {
      console.log(a1, a2);
      return false;
    }

    return all_equal;
  }

  // Type mismatch
  return false;
};

export const equal = (f1, f2) => {
  if ((f1 === null && f2 !== null) || (f1 !== null && f2 === null)) {
    return false;
  }

  return arrays_equal(f1.geometry.coordinates, f2.geometry.coordinates);
};

export const polygon = (coordinates, properties = {}) => {
  const poly = {
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: coordinates,
    },
    properties: properties,
  };
  return poly;
};
