import "./L.textbox";
import turf from "Util/paintpolygon/myTurf.js";
import "../util/paintpolygon/PaintPolygon.css";
import "Util/L.edit/Edit.Rectangle.js";
import "Util/contours/MarchingSquares.js";
import { v4 as uuidv4 } from "uuid";

import "leaflet-easybutton";
import "../util/fontawesome/js/all";
import { elen, Ontology, TileBuffer, SAM } from "../primitives";

function intersectRectangles(rectangle1, rectangle2) {
  // Find the overlapping area
  let xOverlap = Math.max(
    0,
    Math.min(rectangle1.bottom_right.x, rectangle2.bottom_right.x) -
      Math.max(rectangle1.top_left.x, rectangle2.top_left.x)
  );
  let yOverlap = Math.max(
    0,
    Math.min(rectangle1.bottom_right.y, rectangle2.bottom_right.y) -
      Math.max(rectangle1.top_left.y, rectangle2.top_left.y)
  );

  // If there is no overlap, return null
  if (xOverlap === 0 || yOverlap === 0) {
    return null;
  }

  // Calculate the intersection rectangle
  return {
    top_left: {
      x: Math.max(rectangle1.top_left.x, rectangle2.top_left.x),
      y: Math.max(rectangle1.top_left.y, rectangle2.top_left.y),
    },
    bottom_right: {
      x: Math.min(rectangle1.bottom_right.x, rectangle2.bottom_right.x),
      y: Math.min(rectangle1.bottom_right.y, rectangle2.bottom_right.y),
    },
  };
}

const STATES = Object.freeze({
  NORMAL: "normal",
  VISUAL: {
    // Single/multiple select
    CLICK: "visual_click",
    // Area select
    BLOCK: "visual_block",
    FREEFORM: "visual_freeform",
  },
  INSERT: {
    DRAW: "insert_draw",
    DRAW_EXCL: "insert_draw_exclusive",
    SEG_OUTLINE: "insert_seg_outline",
    ERASE: "insert_erase",
  },
  SAVING: "saving",
  // state SELECT_TILE
  // Hovering over tiles to select one to edit
  SELECT_TILE: "select_tile",
});

const AnnotationControl = L.Control.extend({
  options: {
    ontology: {},
    // position: "topright",

    tile_select: true,
    tile_size: 512, //FIXME

    radius: {
      min: 10,
      max: 50,
    },
    draw_style: {
      weight: 1,
      color: "#ffff4a",
    },
    erase_style: {
      color: "#ff324a",
      weight: 1,
    },
  },

  _tooltip: {
    layer: null,
    location: [0, 0],
    radius: 30,
    mousedown: false,
    location_buffer: [],
  },

  _state: STATES.NORMAL,

  initialize: function (options, layer, store) {
    Object.entries(options).forEach(([k, v]) => {
      this.options[k] = v;
    });
    L.setOptions(this, this.options);

    this.layer = layer; // [L.elen, L.geojson]
    this._tile_buffer = new TileBuffer(this);
    this.store = store;
    this._SAM = new SAM(store);
    this.callbacks = {};
  },

  /**
   * Function called when the AnnotationControl is added
   * to the map on init.
   * @param {L.Map} map map where control is being added
   */
  onAdd: function (map) {
    this._map = map;
    try {
      this._map.on("mousemove", this.onMouseMove, this);
      this._map.on("mousedown", this.onMouseDown, this);
      this._map.on("mouseup", this.onMouseUp, this);

      this.radio_callback = (key) => {
        Object.keys(this._tile_buffer.features.selected).map((k) => {
          this._tile_buffer.features.selected[k].properties.type = key;
        });
      };
      this.checkbox_callback = ([key, options], e) => {
        if (this.layer?.updateOntology)
          this.layer.updateOntology([key, options], e);
        this.options.ontology.options[key].visible = e.target.checked;
        this._tile_buffer.refresh();
      };

      // Give layer access to annotation control
      this.layer._annotation_control = this;

      // Load all features from file into the buffer
      // as a single chunk
      if (this.store.is_file()) {
        // GeoJSON: read entire file memory if tile_buffer empty
        if (this._tile_buffer.is_empty()) {
          this.store.read().then((data) => {
            console.log("Data read from store: ", { data });
            this._tile_buffer.load(data, {
              id: "untiled",
              coords: {}, // No coords
            });
          });
        }
      } else {
        // ElenGJ layer: directory implies tiling
        this.layer.addTo(this._map);
      }

      // Add text to display current state
      this.state_text = L.control.textBox(this._state);
      this.state_text.addTo(this._map);
    } catch (err) {
      console.log(err);
    }

    this._tile_buffer.refresh();
    return L.DomUtil.create("div");
  },

  update_state(new_state) {
    this._state = new_state;
    this.state_text.update(new_state);

    this.update_tooltip();

    switch (new_state) {
      case STATES.SAVING:
      case STATES.SELECT_TILE:
      case STATES.NORMAL: {
        this._tile_buffer.shift_all("selected", "normal");
        this._tile_buffer.refresh();
        break;
      }
      default:
        break;
    }

    if (new_state == STATES.SAVING) {
      this.save(false);
    }
  },

  /**
   * Converts a coords array of form [x, y] into a string
   * in form `x.y`.
   * @param {Array} coords coordinates as [x,y]
   * @param {String} type type of tile_id. Either "tile" or "pixel"(micron)
   * @returns {String} coordinates in form `x.y` for ids
   */
  _get_tile_id: function (coords, type = "pixel") {
    if (type === "pixel") {
      return `${coords.x}.${coords.y}`;
    } else if (type === "tile") {
      return `${coords.x / this.options.tile_size}.${
        coords.y / this.options.tile_size
      }`;
    }
  },

  save: function (implicit = false) {
    if (!this._tile_buffer.tile.id) {
      return;
    }

    if (implicit) {
      if (!confirm(`Tile has unsaved changes. Save?`)) {
        return false;
      }
    }

    this._tile_buffer.save_to(this.store).then(() => {
      this.update_state(STATES.NORMAL);
    });
    return true;
  },

  /**
   * Called when a tile is clicked. Will add tile to _tile_buffer
   * clicked tile was not previously selected.
   * @param {*} event
   * @param {*} tile
   * @param {Array} coords micron coordinates as [x,y]
   */
  tile_click: async function (event, tile, coords) {
    if (this._state === STATES.SELECT_TILE) {
      // Provide visual shadow and get id from coords
      tile.style.boxShadow = "0 0 40px rgba(0, 0, 0, 0.8)";
      const tile_id = this._get_tile_id(coords);

      if (tile_id === this._tile_buffer.tile.id) {
        this.tile_mouseout(undefined, this._tile_buffer.tile.html);
        this._tile_buffer.remove();
        this._tile_buffer.tile = {
          html: null,
          coords: {},
          id: null,
        };
        if (this.layer.redraw) {
          this.layer.redraw(); // Removes previously drawn nuclei
        }
        this.update_state(STATES.NORMAL);
      } else {
        this._tile_buffer.tile.html = tile;
        if (this._tile_buffer.tile.html !== null) {
          this.tile_mouseout(undefined, this._tile_buffer.tile.html);
        }

        this.store.read([coords.x, coords.y, 0].join(".")).then((features) => {
          this._tile_buffer.remove();
          this._tile_buffer.load(features, { id: tile_id, coords: coords });
          this._tile_buffer.refresh();

          this.update_state(STATES.NORMAL);
          this.update_tooltip();
          if (this.layer.redraw) {
            this.layer.redraw(); // Removes previously drawn nuclei
          }
        });
      }
    }
  },

  /**
   * Callback when mouse enters tile. Adds shadow for any tile
   * hovered over when in SELECT_TILE state.
   */
  tile_mouseover: function (event, tile, coords) {
    if (this._state === STATES.SELECT_TILE) {
      tile.style.boxShadow = "0 0 20px rgba(0, 0, 0, 0.8)";
    }
  },

  /**
   * Callback when mouse leaves tile. Removes shadow for non-selected
   * tiles when in SELECT_TILE state.
   */
  tile_mouseout: function (event, tile, coords) {
    if (this._state === STATES.SELECT_TILE) {
      tile.style.boxShadow = "none";
    }
  },

  onRemove: function () {
    this._map.off("mousemove", this.onMouseMove, this);
    this._map.off("mousedown", this.onMouseDown, this);
    this._map.off("mouseup", this.onMouseUp, this);
    if (this._tooltip.layer) {
      this._tooltip.layer.remove();
    }
    if (this.layer._map !== null) {
      this.layer.remove();
    }
    this._tile_buffer.remove(); // Removes all layers within tile buffer
    this.state_text.remove();
  },

  /**
   * Callback function when individual feature is clicked
   * within tile stored in _tile_buffer.
   */
  click_feature: function (event) {
    const layer = event.target;
    const feature = layer.feature;

    if (this._state === STATES.VISUAL.CLICK || this._state === STATES.NORMAL) {
      if (feature.properties.id in this._tile_buffer.features.selected) {
        // Feature already selected, set to normal
        this._tile_buffer.shift(feature, "normal");

        if (this.callbacks?.update_ontology_control) {
          this.callbacks.update_ontology_control(null);
        }
        if (Object.keys(this._tile_buffer.features.selected).length === 0) {
          this.update_state(STATES.NORMAL);
        }
      } else {
        if (event.originalEvent.shiftKey) {
          this._tile_buffer.shift(feature, "selected");

          if (this.callbacks?.update_ontology_control) {
            this.callbacks.update_ontology_control(null);
          }
          this.update_state(STATES.VISUAL.CLICK);
        } else {
          if (Object.keys(this._tile_buffer.features.selected).length === 1) {
            this._tile_buffer.shift_all("selected", "normal");
          }
          this._tile_buffer.shift(feature, "selected");
          if (this.callbacks?.update_ontology_control) {
            this.callbacks.update_ontology_control(feature.properties.type);
          }
          this.update_state(STATES.VISUAL.CLICK);
        }
      }
      this._tile_buffer.refresh();
    }
  },
  split_feature: function () {
    if (this._tile_buffer.features.selected) {
      let feature = Object.values(this._tile_buffer.features.selected)[0];
      feature = elen.clean(feature);
      switch (feature.geometry.type) {
        case "MultiPolygon": {
          let polygons = feature.geometry.coordinates.map((c) => {
            return elen.polygon(c, { ...feature.properties, id: uuidv4() });
          });
          polygons.forEach((f) => {
            this._tile_buffer.features.selected[f.properties.id] = f;
          });
          delete this._tile_buffer.features.selected[feature.properties.id];
          this._tile_buffer.refresh(["selected"]);
          break;
        }
        default:
          return;
      }
    }
  },
  merge_feature: function () {
    if (this._tile_buffer.features.selected) {
      let selected = Object.values(this._tile_buffer.features.selected);
      let feature = elen.clean(turf.union(elen.feature_collection(selected)));
      feature.properties = selected[0].properties;
      selected.forEach((f) => {
        delete this._tile_buffer.features.selected[f.properties.id];
      });
      this._tile_buffer.features.selected[feature.properties.id] = feature;
      this._tile_buffer.refresh(["selected"]);
    }
  },

  onMouseMove: function (event) {
    if (event.latlng) {
      this._tooltip.location = [event.latlng.lat, event.latlng.lng]; // [x, y]
      this.update_tooltip();
    }

    if (
      this._state === STATES.INSERT.DRAW ||
      this._state === STATES.INSERT.DRAW_EXCL ||
      this._state === STATES.INSERT.ERASE ||
      this._state === STATES.VISUAL.FREEFORM
    ) {
      if (!this._tooltip.mousedown) return;
      let circle = elen.circle(
        this._tooltip.location,
        this._tooltip.radius / Math.pow(2, this._map.getZoom()),
        128,
        { id: uuidv4(), type: "neoplastic" }
      );

      const x_tile = this._tile_buffer.tile.coords.x;
      const y_tile = this._tile_buffer.tile.coords.y;
      if (x_tile && y_tile) {
        let updated_circle = turf.intersect(
          circle,
          //FIXME
          elen.box(x_tile * 512.0, y_tile * 512.0, 512.0, 512.0)
        ); // Keeps circle within selected tile bounds
        if (!updated_circle) {
          circle = elen.empty(circle.properties);
        } else {
          circle = { ...updated_circle, properties: circle.properties };
        }
      }

      if (this._state === STATES.VISUAL.FREEFORM) {
        if (event.originalEvent.shiftKey) {
          for (let i in this._tile_buffer.features["normal"]) {
            const other_feature = this._tile_buffer.features["normal"][i];
            if (other_feature.properties.id === circle.properties.id) {
              continue; // Don't xor self
            }
            let cleaned_other_feature = elen.clean(other_feature);
            let has_intersect;
            try {
              has_intersect = turf.intersect(cleaned_other_feature, circle);
            } catch (err) {
              console.log(err);
              console.log("difference, upd", cleaned_other_feature);
            }

            if (has_intersect) {
              this._tile_buffer.shift(other_feature, "selected");
            }
          }
        }

        // Refresh layers
        this._tile_buffer.refresh(["normal", "selected"]);
      } else {
        if (Object.keys(this._tile_buffer.features.selected).length === 0) {
          if (
            this._state === STATES.INSERT.DRAW ||
            this._state === STATES.INSERT.DRAW_EXCL
          ) {
            this._tile_buffer.features.selected[circle.properties.id] = circle;
          }
        } else {
          // Editing Selected Item
          let feature = Object.values(this._tile_buffer.features.selected)[0];
          feature = elen.clean(feature);

          if (Object.keys(this._tile_buffer.features.selected).length > 1) {
            this._tile_buffer.shift_all("selected", "normal");
            this._tile_buffer.shift(feature, "selected");
            this._tile_buffer.refresh(["selected", "normal"]);
          }

          let updated_feature = undefined;
          try {
            if (
              this._state === STATES.INSERT.DRAW ||
              this._state === STATES.INSERT.DRAW_EXCL
            ) {
              updated_feature = turf.union(
                elen.feature_collection([feature, circle])
              );

              if (this._state === STATES.INSERT.DRAW_EXCL) {
                for (let i in this._tile_buffer.features["normal"]) {
                  const other_feature = this._tile_buffer.features["normal"][i];
                  if (other_feature.properties.id === feature.properties.id) {
                    continue; // Don't xor self
                  }
                  if (
                    !other_feature.geometry ||
                    other_feature.geometry === null
                  ) {
                    continue; // Non-existent geometry
                  }

                  let updated_other_feature = elen.clean(other_feature);

                  try {
                    updated_other_feature = turf.difference(
                      updated_other_feature,
                      circle
                    );
                  } catch (err) {
                    console.log(err);
                    console.log("difference, upd", updated_other_feature);
                  }

                  // Assume other features aren't selected
                  if (updated_other_feature === null) {
                    delete this._tile_buffer.features.normal[
                      other_feature.properties.id
                    ];
                    updated_other_feature = undefined;
                  }

                  if (updated_other_feature !== undefined) {
                    updated_other_feature.properties = other_feature.properties;
                    this._tile_buffer.features.normal[
                      updated_other_feature.properties.id
                    ] = updated_other_feature;
                  }
                }
                // Refresh layers
                this._tile_buffer.refresh(["normal", "selected"]);
              }
            } else {
              // ERASE
              updated_feature = turf.difference(feature, circle);
              if (updated_feature === null) {
                delete this._tile_buffer.features.selected[
                  feature.properties.id
                ];
              }
            }

            if (updated_feature !== undefined) {
              updated_feature.properties = feature.properties;
              updated_feature.properties.state = "selected";
              this._tile_buffer.features.selected[
                updated_feature.properties.id
              ] = updated_feature;
            }
          } catch (err) {
            console.log("Error:", err);
            console.trace();
          }
        }
      }

      this._tile_buffer.refresh(["selected"]);
    }
  },

  onMouseDown: function (event) {
    if (!this._map) {
      return;
    }

    if (
      this._state === STATES.INSERT.DRAW ||
      this._state === STATES.INSERT.DRAW_EXCL ||
      this._state === STATES.INSERT.ERASE ||
      (this._state === STATES.VISUAL.FREEFORM && event.originalEvent.shiftKey)
    ) {
      this._map.dragging.disable();
    }

    if (
      this._state === STATES.VISUAL.BLOCK ||
      this._state === STATES.INSERT.SEG_OUTLINE
    ) {
      this._tooltip.location_buffer.push([event.latlng.lat, event.latlng.lng]);
      this._map.dragging.disable();
    }

    this._tooltip.mousedown = true;
    this.onMouseMove(event);
  },

  onMouseUp: function (event) {
    if (!this._map) {
      return;
    }

    if (
      this._state === STATES.INSERT.DRAW ||
      this._state === STATES.INSERT.DRAW_EXCL ||
      this._state === STATES.INSERT.ERASE ||
      this._state === STATES.VISUAL.FREEFORM
    ) {
      this._map.dragging.enable();
    }

    if (this._state === STATES.VISUAL.BLOCK) {
      const p1 = this._tooltip.location_buffer.pop();
      const p2 = this._tooltip.location;

      const top_left = [Math.min(p1[0], p2[0]), Math.min(p1[1], p2[1])];
      const bottom_right = [Math.max(p1[0], p2[0]), Math.max(p1[1], p2[1])];

      const selected = Object.values(
        this._tile_buffer.features["normal"]
      ).filter((feature) => {
        // Don't select features that aren't visible
        if (
          !(feature.properties.type in this.layer.ontology.options) ||
          !this.layer.ontology.options[feature.properties.type].visible
        )
          return false;

        const coords = turf.pointOnFeature(feature).geometry.coordinates;
        return (
          top_left[0] < coords[1] &&
          top_left[1] < coords[0] &&
          bottom_right[0] > coords[1] &&
          bottom_right[1] > coords[0]
        );
      });

      selected.forEach((feature) =>
        this._tile_buffer.shift(feature, "selected")
      );
      this._tile_buffer.refresh();
      this.update_state(STATES.VISUAL.CLICK);
      this.update_tooltip();

      this._map.dragging.enable();
    }

    if (this._state === STATES.INSERT.SEG_OUTLINE) {
      const p1 = this._tooltip.location_buffer.pop();
      const p2 = this._tooltip.location;
      const top_left = [Math.min(p1[0], p2[0]), Math.min(p1[1], p2[1])]; // (y, x)
      const bottom_right = [Math.max(p1[0], p2[0]), Math.max(p1[1], p2[1])]; // (y, x)
      // Check if [RECT] in bounds of tile
      if (this._tile_buffer.tile.id === null) {
        alert("No tile has been selected. Select one first.");
      } else {
        // Create adjustible bounding box
        const tile_rect = {
          top_left: {
            x: this._tile_buffer.tile.coords.x * this.options.tile_size,
            y: this._tile_buffer.tile.coords.y * this.options.tile_size,
          },
          bottom_right: {
            x: (this._tile_buffer.tile.coords.x + 1) * this.options.tile_size,
            y: (this._tile_buffer.tile.coords.y + 1) * this.options.tile_size,
          },
        };

        const cur_rect = {
          top_left: {
            x: top_left[1],
            y: top_left[0],
          },
          bottom_right: {
            x: bottom_right[1],
            y: bottom_right[0],
          },
        };

        const intersect_rect = intersectRectangles(tile_rect, cur_rect);

        if (intersect_rect === null) {
          alert("Outline not within tile bounds.");
        } else {
          var rect = L.rectangle([
            [intersect_rect.top_left.y, intersect_rect.top_left.x],
            [intersect_rect.bottom_right.y, intersect_rect.bottom_right.x],
          ]);
          rect.addTo(this._map);
          rect.editing.enable();

          this._map.eachLayer(
            async function (layer) {
              if (layer.GeoTIFF) {
                const TARGET_MPP = 0.125;
                const IMG_WIDTH = 1024;
                const IMG_HEIGHT = 684;
                let image_embeddings;
                let mask_image_data;
                let origin;
                let closestLevel, mpp, tile_size;
                try {
                  ({
                    level_idx: closestLevel,
                    level_mpp: mpp,
                    tile_size,
                  } = layer.getClosestLevel(TARGET_MPP));

                  const MEGATILE_WIDTH = Math.floor(IMG_WIDTH / tile_size);
                  const MEGATILE_HEIGHT = Math.floor(IMG_HEIGHT / tile_size);

                  // Get Elen layer tile coordinates (microns)
                  const tile_x = this._tile_buffer.tile.coords.x * 512;
                  const tile_y = this._tile_buffer.tile.coords.y * 512;

                  // Closest tile index of this tile w.r.t georaster grid
                  const tile_raster_index_x = Math.floor(
                    tile_x / mpp / tile_size
                  );
                  const tile_raster_index_y = Math.floor(
                    tile_y / mpp / tile_size
                  );

                  // Closest tile index of bounding box upper left
                  const curr_raster_index_x = Math.floor(
                    intersect_rect.top_left.x / mpp / tile_size
                  );
                  const curr_raster_index_y = Math.floor(
                    intersect_rect.top_left.y / mpp / tile_size
                  );

                  // A 'megatile' is a concatenation of several tiles
                  // w.r.t Georaster layer. Used to maximize number of
                  // tiles that can be encoded in one shot (leveraging batch inference)
                  const megatile_x = Math.floor(
                    (curr_raster_index_x - tile_raster_index_x) / MEGATILE_WIDTH
                  );
                  const megatile_y = Math.floor(
                    (curr_raster_index_y - tile_raster_index_y) /
                      MEGATILE_HEIGHT
                  );

                  // Tile indices w.r.t Georaster layer tile space
                  const index_x =
                    megatile_x * MEGATILE_WIDTH + tile_raster_index_x;
                  const index_y =
                    megatile_y * MEGATILE_HEIGHT + tile_raster_index_y;

                  // Pixel coordinate of top of image block w.r.t georaster layer
                  origin = {
                    x: index_x * tile_size,
                    y: index_y * tile_size,
                  };

                  const stitchImageData = await layer.getImageData(
                    {
                      x: index_x,
                      y: index_y,
                      w: MEGATILE_WIDTH,
                      h: MEGATILE_HEIGHT,
                    },
                    closestLevel
                  );

                  const uri_id_arr = layer.uri.split("/");
                  const uri_id = uri_id_arr[uri_id_arr.length - 1];
                  image_embeddings = await this._SAM.encode(
                    stitchImageData,
                    [uri_id, index_x, index_y].join("_") // Unique ID for each patch for caching
                  );

                  const pix = (micron) => {
                    return micron / mpp;
                  };

                  // Bounding box in pixels w.r.t georaster layer
                  // relative to origin
                  const bbox = {
                    x: pix(intersect_rect.top_left.x) - origin.x,
                    y: pix(intersect_rect.top_left.y) - origin.y,
                    w:
                      pix(intersect_rect.bottom_right.x) -
                      pix(intersect_rect.top_left.x),
                    h:
                      pix(intersect_rect.bottom_right.y) -
                      pix(intersect_rect.top_left.y),
                  };

                  mask_image_data = await this._SAM.decode(
                    image_embeddings,
                    bbox
                  );
                } catch (error) {
                  console.error("Error computing SAM prediction.", error);
                }

                const new_feature = this._SAM.postprocess(
                  mask_image_data,
                  origin,
                  mpp
                );

                this._tile_buffer.features.normal[new_feature.properties.id] =
                  new_feature;
                this._tile_buffer.refresh(["normal"]);
                rect.editing.disable();
                rect.remove();
              }
            }.bind(this)
          );
        }
      }

      this.update_state(STATES.VISUAL.CLICK);
      this.update_tooltip();
      this._map.dragging.enable();
    }

    this._tooltip.mousedown = false;
    this.onMouseMove(event);
  },

  // TODO: after function, think how to beter abstract this
  // Idea: Another layer / place to define user actions (?)
  model_embed: function () {
    // TODO: Same code as before, just modified a bit
    this._map.eachLayer(
      async function (layer) {
        if (layer.GeoTIFF) {
          const TARGET_MPP = 0.125;
          const IMG_WIDTH = 1024;
          const IMG_HEIGHT = 684;
          let closestLevel, mpp, tile_size;
          try {
            ({
              level_idx: closestLevel,
              level_mpp: mpp,
              tile_size,
            } = layer.getClosestLevel(TARGET_MPP));

            // TODO: Move this entire computation to some initialization
            const MEGATILE_WIDTH = Math.floor(IMG_WIDTH / tile_size);
            const MEGATILE_HEIGHT = Math.floor(IMG_HEIGHT / tile_size);

            // Get Elen layer tile coordinates - upper left and bottom right (microns)
            const tile_x = this._tile_buffer.tile.coords.x * 512;
            const tile_y = this._tile_buffer.tile.coords.y * 512;

            // Closest tile index of this tile w.r.t georaster grid
            const tile_raster_index_x = Math.floor(tile_x / mpp / tile_size);
            const tile_raster_index_y = Math.floor(tile_y / mpp / tile_size);

            // Pixel coordinate of the closest tile containing
            // this Tile's origin w.r.t georaster grid
            const tile_raster_x = tile_raster_index_x * tile_size;
            const tile_raster_y = tile_raster_index_y * tile_size;

            // Get direct pixel coordinates for bottom left corner
            // w.r.t georaster grid
            const bound_tile_raster_x =
              ((this._tile_buffer.tile.coords.x + 1) * 512) / mpp;
            const bound_tile_raster_y =
              ((this._tile_buffer.tile.coords.y + 1) * 512) / mpp;

            // Compute how many megatiles are necessary to
            // encapsulate the entire tile
            // Difference in pixel coordinates w.r.t georaster of bottom-left
            // and upper-right of tile.
            const tile_raster_width = bound_tile_raster_x - tile_raster_x;
            const tile_raster_height = bound_tile_raster_y - tile_raster_y;

            // Perform +1 to ensure bottom right is included in bottom rightmost megatile
            const n_tiles_width = Math.floor(tile_raster_width / tile_size) + 1;
            const n_tiles_height =
              Math.floor(tile_raster_height / tile_size) + 1;

            // Number of megatiles indices to iterate over
            const n_megatiles_width = Math.floor(
              n_tiles_width / MEGATILE_WIDTH
            );
            const n_megatiles_height = Math.floor(
              n_tiles_height / MEGATILE_HEIGHT
            );

            // Extract and embed each tile
            for (let mt_x = 0; mt_x < n_megatiles_width; mt_x++) {
              for (let mt_y = 0; mt_y < n_megatiles_height; mt_y++) {
                // Tile indices w.r.t Georaster layer tile space
                const index_x = mt_x * MEGATILE_WIDTH + tile_raster_index_x;
                const index_y = mt_y * MEGATILE_HEIGHT + tile_raster_index_y;

                const stitchImageData = await layer.getImageData(
                  {
                    x: index_x,
                    y: index_y,
                    w: MEGATILE_WIDTH,
                    h: MEGATILE_HEIGHT,
                  },
                  closestLevel
                );

                const uri_id_arr = layer.uri.split("/");
                const uri_id = uri_id_arr[uri_id_arr.length - 1];
                let image_embeddings = await this._SAM.encode(
                  stitchImageData,
                  [uri_id, index_x, index_y].join("_") // Unique ID for each patch for caching
                );
                // image_embeddings are auto-cached by SAM
              }
            }
          } catch (error) {
            console.error("Error computing SAM embeddings.", error);
          }
        }
      }.bind(this)
    );
  },

  update_tooltip: function () {
    if (!this._map) {
      return;
    }

    switch (this._state) {
      case STATES.NORMAL:
      case STATES.VISUAL.CLICK: {
        if (this._tooltip.layer) {
          this._tooltip.layer.remove();
        }
        this._tooltip.layer = null;
        break;
      }
      case STATES.INSERT.DRAW:
      case STATES.INSERT.DRAW_EXCL:
      case STATES.INSERT.ERASE:
      case STATES.VISUAL.FREEFORM: {
        this._tooltip.radius = elen.clamp(
          this._tooltip.radius,
          this.options.radius.min,
          this.options.radius.max
        );

        const layer_options =
          this._state === STATES.INSERT.DRAW ||
          this._state === STATES.INSERT.DRAW_EXCL ||
          this._state === STATES.VISUAL.FREEFORM
            ? this.options.draw_style
            : this.options.erase_style;
        const latlng = {
          lat: this._tooltip.location[0],
          lng: this._tooltip.location[1],
        };
        if (!this._tooltip.layer) {
          this._tooltip.layer = L.circleMarker(latlng).addTo(this._map);
        }

        this._tooltip.layer
          .setRadius(this._tooltip.radius)
          .setLatLng(latlng)
          .setStyle(layer_options);
        break;
      }
      case STATES.INSERT.SEG_OUTLINE:
      case STATES.VISUAL.BLOCK: {
        if (!this._tooltip.mousedown) {
          break;
        }
        if (!this._tooltip.layer) {
          const props = {
            weight: 2,
            color: "#daead0",
            className: "rect-progress-line",
            opacity: 0.6,
          };
          this._tooltip.layer = L.layerGroup()
            .addLayer(L.polyline([], props))
            .addLayer(L.polyline([], props))
            .addTo(this._map);
        }
        const layers = this._tooltip.layer.getLayers();

        const start_location = this._tooltip.location_buffer[0];
        const end_location = this._tooltip.location;
        const latlng = (xy) => {
          return { lat: xy[0], lng: xy[1] };
        };
        layers[0].setLatLngs([
          latlng(start_location),
          latlng([start_location[0], end_location[1]]),
          latlng(end_location),
        ]);
        layers[1].setLatLngs([
          latlng(start_location),
          latlng([end_location[0], start_location[1]]),
          latlng(end_location),
        ]);
      }
      default:
        break;
    }
  },
});

L.Control.AnnotationControl = AnnotationControl;
L.control.annotationControl = (options, layer, store) =>
  new L.Control.AnnotationControl(options, layer, store);

export { AnnotationControl };
